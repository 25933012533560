/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
$app-direction: rtl;

.table-header{
    font-weight: bolder;
    text-align: center;
    background-color: #b8d3ff;
}
.table-header ion-col{
    border:1px solid white;
}
.table-rows ion-col{
    border:0.2px solid #b8d3ff;
}
body{
    font-family: "Calibri";
}
ion-label{
    font-weight: bolder;
}
ion-button{
    font-weight:bolder;
    font-family:"Calibri" !important;
    font-weight:bolder!important;
}

ion-title{
    font-weight:bolder;
    font-family:"Calibri" !important;
}
ion-label{
    font-weight:bolder;
    font-family:"Calibri" !important;
}
h1{
    font-family:"Calibri";
    font-weight: bolder;
}
h2{
    font-family:"Calibri";
    font-weight: bolder;
}
h3{
    font-family:"Calibri";
    font-weight: bolder;
}
h4{
    font-family:"Calibri";
    font-weight: bolder;
}
.dari{
    font-family:"Calibri"
}